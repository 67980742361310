import React, { Fragment } from "react"
import Upload from "../../../components/MedicineAvailement/Upload"

export default () => {
  return (
    <Fragment>
      <Upload />
    </Fragment>
  )
}
