import React, { useContext } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"

import Layout from "../Layout/Layout"
import Container from "../Layout/Container"
import FormSelect from "elements/Form/FormSelect"
import ActionButtons from "elements/ActionButtons"
import UploadGuidelines from "elements/UploadGuidelines"
import UploadDocumentDropzone from "../Form/UploadDocuments/UploadDocumentDropzone"

import { AppContext } from "../../context/AppContext"
import styles from "../Layout/utils/layout.module.scss"
import { medicineOptions } from "./utils/medicineOptions"

const Upload = () => {
  const { state, dispatch } = useContext(AppContext)

  let currentReceiptsUploaded = state?.medicineAvailment?.receipts

  let nextLink =
    currentReceiptsUploaded.length > 0
      ? "/giotrif/medicine-availment/receipt"
      : "/giotrif/medicine-availment/receipt/upload"

  return (
    <Layout
      title="Upload Prescription"
      seoTitle="Upload Prescription"
      subtitle="Please upload your latest prescription."
    >
      <Container isCentered desktop={10} fullhd={8}>
        <div className={classNames("columns is-centered", styles["container"])}>
          <div className="column pb-0-mobile is-7">
            <UploadGuidelines />
            <UploadDocumentDropzone
              docType="rx"
              label="Upload Prescription"
              icon="prescription"
              maxFiles={6}
            />
            <Formik initialValues={state.medicineAvailment}>
              {({ values }) => (
                <Form>
                  <FormSelect
                    name="desiredMedicine"
                    label="What medicine assistance do you want to receive?"
                    placeholder="Afatinib (Giotrif) 30mg Tablet"
                    value={values.desiredMedicine}
                    options={medicineOptions}
                    onChange={newValue => {
                      dispatch({
                        type: "SAVE_MEDICINE_AVAILMENT",
                        payload: { desiredMedicine: newValue },
                      })
                    }}
                    isRequired
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <ActionButtons
          back={{
            label: "Back",
            link: "/giotrif/medicine-availment",
          }}
          next={{
            label: "Next",
            link: nextLink,
            disabled:
              !state?.documents.length ||
              state?.medicineAvailment?.desiredMedicine?.value === null,
          }}
        />
      </Container>
    </Layout>
  )
}

export default Upload
