export const medicineOptions = [
  {
    value: "Afatinib (Giotrif) 30mg Tablet",
    label: "Afatinib (Giotrif) 30mg Tablet",
  },
  {
    value: "Afatinib (Giotrif) 40mg Tablet",
    label: "Afatinib (Giotrif) 40mg Tablet",
  },
]
